<template>
  <div>
    <div class="client-container">
      <div
        class="message-container"
        id="msg-id-cont"
        @scroll="scrollHandle"
        ref="messageContainer"
      >
        <div v-for="message in room.messages" :key="message.timestamp">
          <p :class="message.ownMessage ? 'name-right' : 'name-left'">
            {{ message.senderDisplayName }}
          </p>
          <p
            :class="message.ownMessage ? 'me-msg' : 'you-msg'"
            v-html="
              parseMessage(
                message.content,
                message.timestamp,
                message.senderDisplayName
              )
            "
          />
        </div>
      </div>
      <div class="bottom-bar">
        <!--label for="fileUpload" class="upload-documents">
          <br />
          <br />+
        </label>
        <input
          type="file"
          name="fileUpload"
          id="fileUpload"
          multiple="multiple"
          @change="parseUploadedFile"
          :disabled="disabled"
        /-->
        <textarea
          class="msg-input"
          v-model="currentlyWriting"
          @keypress.enter.exact.prevent="sendMessage"
          @keypress.shift.enter.exact.prevent="
            currentlyWriting =
              currentlyWriting.slice(0, $refs.messageInput.selectionStart) +
              '\n' +
              currentlyWriting.slice($refs.messageInput.selectionStart)
          "
          ref="messageInput"
          @input="
            $refs.messageInput.style.height = '';
            $refs.messageInput.style.height =
              Math.min($refs.messageInput.scrollHeight, 150) + 'px';
            $refs.messageContainer.style.height =
              '100hv - ' + $refs.messageInput.style.height + 'px';
          "
        >
        </textarea>
        <!--div
          class="emoji-div"
          v-click-outside="hideEmojis"
          :style="{ right: getPos() }"
        >
          <div class="emoji-container" v-show="showEmoji">
            <span
              v-for="n in 79"
              @click="
                currentlyWriting =
                  currentlyWriting.slice(0, $refs.messageInput.selectionStart) +
                  getEmoticoneCode(n) +
                  currentlyWriting.slice($refs.messageInput.selectionStart)
              "
              :key="n"
              class="an-emoji"
              v-html="getEmoticone(n)"
            ></span>
          </div>
          <button
            class="emoji-button"
            @click="showEmoji = !showEmoji"
            :disabled="disabled"
            v-html="this.$twemoji.parse('\ud83d\ude01')"
          ></button>
        </div-->
        <button class="send-button" @click="sendMessage" :disabled="disabled">
          Send
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomRepository } from "../roomRepository";
import sanitizeHtml from "sanitize-html";

export default {
  name: "TicketMessages",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    roomType: {
      type: String,
    },
    roomId: {
      type: String,
    },
  },
  watch: {
    roomId() {
      this.room = getRoomRepository().getRoom(this.roomType, this.roomId);
      this.room.enablePeriodicUpdates();
      this.registerRoomCheck(this.roomId);
      this.currentlyWriting = "";
      this.$nextTick(() => {
        this.$refs.messageContainer.scrollTop =
          this.$refs.messageContainer.scrollHeight;
      });
    },
  },
  data() {
    return {
      //showEmoji: false,
      currentlyWriting: "",
      room: getRoomRepository().getRoom(this.roomType, this.roomId),
      loadingMore: false,
      oldLength: 0,
    };
  },
  methods: {
    getPos() {
      var element = document.getElementsByClassName("emoji-button");
      if (element.length > 0) {
        return element[0].getBoundingClientRect().right;
      }
      return -77;
    },
    getEmoticone(n) {
      return this.$twemoji.parse(this.getEmoticoneCode(n));
    },
    getEmoticoneCode(n) {
      return String.fromCodePoint(
        (n < 16 ? "0x1f60" : "0x1f6") + n.toString(16)
      );
    },
    hideEmojis() {
      this.showEmoji = false;
    },
    parseUploadedFile(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.client.upload(file, this.matrixRoomId);
      }
    },
    parseMessage(text, date, messageSender) {
      if (
        messageSender != "event" &&
        !(
          text.hasOwnProperty("msgtype") &&
          (text.msgtype == "m.file" ||
            text.msgtype == "m.image" ||
            text.msgtype == "m.audio" ||
            text.msgtype == "m.video")
        )
      ) {
        text = sanitizeHtml(text.body, { disallowedTagsMode: "escape" });
        text = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
        var exp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        /*let todayDate = new Date();
        let printDate = this.beautifulDate(date, false);
        if (
          todayDate.getFullYear() != date.getFullYear() ||
          todayDate.getMonth() != date.getMonth() ||
          todayDate.getDay() != date.getDay()
        ) {
          printDate = this.beautifulDate(date, true);
        }*/
        // may break
        return (
          text +
          "</br><p class='message-date'>" +
          new Date(date).toLocaleString() +
          "</p>"
        ); /*this.$twemoji.parse(
          text.replace(exp, "<a href='$1' target='_blank'>$1</a>") +
            "</br>" +
            "<p class='message-date'>" +
            printDate +
            "</p>"
        );*/
      } else {
        if (messageSender == "event") {
          return text;
        } else {
          let docUrl = text.url.substring(6);
          if (text.msgtype == "m.image") {
            return (
              '<img style="width:100%;" src="' +
              this.room.mxcToHttps(text.url) +
              '" onerror="fetch(\'' +
              this.room.mxcToHttps(text.url) +
              "', { headers: { Authorization: '" +
              this.$store.getters.token +
              "' } }).then(r => fetch(r.url).then(r2 => r2.blob()).then(b => this.src = window.URL.createObjectURL(b)));\" />"
            );
          } else {
            return text.body;
            if (text.msgtype == "m.audio") {
              return (
                '<audio controls> <source src="' +
                this.matrixConf.baseUrl +
                "/_matrix/media/r0/download/" +
                docUrl +
                '" type="' +
                text.info.mimetype +
                '"> Your browser does not support the audio element. </audio>' +
                '<a target="_blank" rel="noopener" href="' +
                this.matrixConf.baseUrl +
                "_matrix/media/r0/download/" +
                docUrl +
                '">' +
                "<br/>↓ " +
                sanitizeHtml(text.body) +
                "</a>"
              );
            } else {
              if (text.msgtype == "m.video") {
                return (
                  '<video controls height="300"> <source src="' +
                  this.matrixConf.baseUrl +
                  "/_matrix/media/r0/download/" +
                  docUrl +
                  '" type="' +
                  text.info.mimetype +
                  '"> Your browser does not support the video element. </video>' +
                  '<a target="_blank" rel="noopener" href="' +
                  this.matrixConf.baseUrl +
                  "_matrix/media/r0/download/" +
                  docUrl +
                  '">' +
                  "<br/>↓ " +
                  sanitizeHtml(text.body) +
                  "</a>"
                );
              } else {
                return (
                  '<a target="_blank" rel="noopener" href="' +
                  this.matrixConf.baseUrl +
                  "/_matrix/media/r0/download/" +
                  docUrl +
                  '">↓ ' +
                  text.body +
                  "</a>"
                );
              }
            }
          }
        }
      }
    },
    sendMessage() {
      if (this.currentlyWriting.trim() != "") {
        this.room.sendMessage({
          msgtype: "m.text",
          body: this.currentlyWriting.trim(),
        });
        this.currentlyWriting = "";
        this.$nextTick(() => {
          this.$refs.messageInput.style.height = "";
          this.$refs.messageInput.style.height =
            Math.min(this.$refs.messageInput.scrollHeight, 100) + "px";
          this.$refs.messageContainer.style.height =
            "100hv - " + this.$refs.messageInput.style.height + "px";
        });
      }
    },
    async scrollBack() {
      /*let oldHeight = this.$refs.messageContainer.scrollHeight;
      this.loadingMore = true;
      let newMessages = await this.client.scrollback(this.matrixRoomId, load);
      let newMsgCount = 0;
      newMessages.messages.chunk.forEach((message) => {
        if (message.type == "m.room.message") {
          newMsgCount += 1;
        }
      });*/
      this.room.scrollBack();
      /*if (this.sortedMessages.length < newMsgCount) {
        this.messages = this.client.watch();
        this.messages.forEach((message) => {
          if (message.room_id != this.matrixRoomId) {
            this.messages.push(message);
          }
        });
        newMessages.messages.chunk.forEach((message) => {
          if (message.type == "m.room.message") {
            this.messages.push(message);
          }
        });

        this.$nextTick(() => {
          this.$refs.messageContainer.scrollTop =
            this.$refs.messageContainer.scrollHeight - oldHeight;
        });
      }*/
    },
    scrollHandle() {
      if (this.$refs.messageContainer.scrollTop <= 20) {
        this.scrollBack();
      }
    },
    registerRoomCheck(roomId) {
      getRoomRepository()
        .getRoom("travel", roomId)
        .onUpdate(() => {
          if (this.roomId === roomId) {
            this.$nextTick(() => {
              this.$refs.messageContainer.scrollTop =
                this.$refs.messageContainer.scrollHeight;
            });
          }
        });
    },
  },
  mounted() {
    this.room.enablePeriodicUpdates();
    this.registerRoomCheck(this.roomId);
  },
  destroyed() {
    this.room.disablePeriodicUpdates();
  },
};
</script>

<style scoped>
.message-header {
  max-width: 980px;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 20px 20px 0px 0px;
  border: 2px solid lightgrey;
  text-align: left;
  margin: 0 auto;
}

.message-header select {
  background-color: #00909f;
  color: white;
  border: none;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid white;
  padding: 5px;
  width: 500px;
  max-width: 100%;
}

.message-header label {
  position: relative;
}
.message-header label:after {
  content: "v";
  color: #fff;
  right: 8px;
  top: 2px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}
.message-header label:before {
  content: "";
  right: 6px;
  top: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
  pointer-events: none;
  display: block;
}

.message-header select::-ms-expand {
  display: none;
}

.client-container {
  align-items: center;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  max-width: 1000px;
  border-left: 2px solid lightgrey;
  border-right: 2px solid lightgrey;
}

.load-more-button-container {
  text-align: center;
}

.load-more-button {
  background-color: rgb(153, 210, 208);
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px;
  border-radius: 20px;
  font-size: 15px;
}

.message-container {
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

.message-container >>> a {
  color: white;
  text-decoration: none;
}

.message-container >>> a:hover {
  text-decoration: underline;
}

.message-container >>> a:visited {
  color: white;
  text-decoration: none;
}

.event {
  color: lightgrey;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 10px;
}

.event >>> p {
  margin: 0;
  color: white;
  font-weight: bold;
  background-color: rgb(153, 210, 208);
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
}

.event >>> hr {
  margin-bottom: 0;
}

.name-right {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: 15px;
  width: max-content;
  color: rgb(130, 130, 130);
}

.name-left {
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(130, 130, 130);
  margin-right: auto;
  margin-left: 0px;
}

.name-no-display {
  display: none;
}

.me-msg {
  background-color: #00909f;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1px;
  word-wrap: break-word;
}

.you-msg {
  background-color: #034c57;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: 0px;
  margin-top: 1px;
  word-wrap: break-word;
}

.he-msg {
  background-color: #009fbc;
  color: white;
  width: max-content;
  max-width: 80%;
  padding: 7px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: 0px;
  margin-top: 1px;
  word-wrap: break-word;
}

.bottom-bar {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr 1fr;
  column-gap: 10px;
  padding-top: 5px;
  border-top: 1px solid lightgrey;
  min-height: 40px;
}

.bottom-bar input {
  width: 95%;
  border-radius: 20px;
  padding-left: 10px;
  border: 1px solid lightgrey;
  z-index: 5;
  padding-right: 10px;
  overflow-wrap: break-word;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

input[type="file"] {
  display: none;
}

.upload-documents {
  background-color: rgb(153, 210, 208);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 8px;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.upload-documents:hover {
  background-color: rgb(106, 173, 171);
}

.emoji-button {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.emoji-button:hover {
  border-color: lightgrey;
}

.send-button {
  background-color: rgb(153, 210, 208);
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  grid-column-start: 4;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.send-button:hover {
  background-color: rgb(106, 173, 171);
}

.emoji-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 250px;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid grey;
  justify-items: center;
  align-items: center;
  bottom: -15px;
  background-color: white;
  position: absolute;
  padding: 5px;
  right: calc(50% - 388px);
}

.an-emoji {
  border: 1px solid white;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
}

.an-emoji:hover {
  border-color: lightgray;
}

@media only screen and (max-width: 1000px) {
  .emoji-container {
    right: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .bottom-bar {
    column-gap: 5px;
  }

  .emoji-div {
    display: none;
  }

  .emoji-button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.an-emoji {
  border: 1px solid white;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
}

.an-emoji:hover {
  border-color: lightgray;
}

.msg-input {
  width: 95%;
  border-radius: 20px;
  padding-left: 15px;
  padding-top: 2px;
  border: 1px solid lightgrey;
  font-size: 16px;
  z-index: 5;
  padding-right: 15px;
  overflow-wrap: break-word;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
  resize: none;
}
</style>

<style>
.message-date {
  text-align: right;
  font-size: 12px;
  color: rgb(196, 196, 196);
  margin-top: 5px;
  margin-bottom: 0;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
video {
  max-width: 70vw;
}

img {
  max-width: 70vw;
}
</style>
