var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"client-container"},[_c('div',{ref:"messageContainer",staticClass:"message-container",attrs:{"id":"msg-id-cont"},on:{"scroll":_vm.scrollHandle}},_vm._l((_vm.room.messages),function(message){return _c('div',{key:message.timestamp},[_c('p',{class:message.ownMessage ? 'name-right' : 'name-left'},[_vm._v(" "+_vm._s(message.senderDisplayName)+" ")]),_c('p',{class:message.ownMessage ? 'me-msg' : 'you-msg',domProps:{"innerHTML":_vm._s(
            _vm.parseMessage(
              message.content,
              message.timestamp,
              message.senderDisplayName
            )
          )}})])}),0),_c('div',{staticClass:"bottom-bar"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentlyWriting),expression:"currentlyWriting"}],ref:"messageInput",staticClass:"msg-input",domProps:{"value":(_vm.currentlyWriting)},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();_vm.currentlyWriting =
            _vm.currentlyWriting.slice(0, _vm.$refs.messageInput.selectionStart) +
            '\n' +
            _vm.currentlyWriting.slice(_vm.$refs.messageInput.selectionStart)}],"input":[function($event){if($event.target.composing){ return; }_vm.currentlyWriting=$event.target.value},function($event){_vm.$refs.messageInput.style.height = '';
          _vm.$refs.messageInput.style.height =
            Math.min(_vm.$refs.messageInput.scrollHeight, 150) + 'px';
          _vm.$refs.messageContainer.style.height =
            '100hv - ' + _vm.$refs.messageInput.style.height + 'px';}]}}),_c('button',{staticClass:"send-button",attrs:{"disabled":_vm.disabled},on:{"click":_vm.sendMessage}},[_vm._v(" Send ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }