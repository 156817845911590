<!-- This component is currently unused -->
<template>
  <ticket-message
    :matrixConf="matrixConf"
    :matrixRoomId="matrixRoomId"
    :clientNameMap="{}"
  ></ticket-message>
</template>

<script>
import HandleApi from "../apiHandle";
import TicketMessage from "./TicketMessages.vue";

export default {
  name: "SupportTchat",
  components: {
    TicketMessage,
  },
  data() {
    return {
      matrixConf: {},
      matrixRoomId: "",
    };
  },
  async created() {
    try {
      let res = await HandleApi.getClientInformation(
        this.$store.getters.userId
      );
      window.console.log(res);
      this.matrixRoomId = res.client.matrixSupportChannel;
      this.matrixConf = await HandleApi.getMessageAuth(
        this.$store.getters.userId
      );
    } catch (err) {
      this.$store.dispatch("unload");
      this.$store.dispatch("push_error_message", err);
    }
  },
};
</script>

<style scoped></style>
